<template>
<v-ons-page class="sending-personal-data">
	<common-header
		type="allow"
		@click="$router.back()"
	>
		<h1 class="common-header-title__heading">パーソナルデータを利用している<br class="is-sp">外部サービス</h1>
	</common-header>
	<article class="sending-personal-data__container" id="js_sending-personal-data_container">
		<h2 class="sending-personal-data__title">このウェブサイトでは以下の第三者が提供するサービスを利用するため、パーソナルデータを送信しています。</h2>
		<section>
			<table class="sending-personal-data__table">
				<tr>
					<th class="sending-personal-data__table--title">サービス名</th>
					<td class="sending-personal-data__table--description">GoogleAnalytics<br>※GoogleTagManager経由</td>
					<td class="sending-personal-data__table--description">Mopo</td>
				</tr>
				<tr>
					<th class="sending-personal-data__table--title">提供会社</th>
					<td class="sending-personal-data__table--description">Google LLC</td>
					<td class="sending-personal-data__table--description">株式会社D2C R</td>
				</tr>
				<tr>
					<th class="sending-personal-data__table--title">提供情報</th>
					<td class="sending-personal-data__table--description">本サイト内の情報への<br>アクセス履歴</td>
					<td class="sending-personal-data__table--description">スゴ得ID<br>セッションID</td>
				</tr>
				<tr>
					<th class="sending-personal-data__table--title">利用目的</th>
					<td class="sending-personal-data__table--description">アクセス解析</td>
					<td class="sending-personal-data__table--description">広告成果計測</td>
				</tr>
			</table>
		</section>
	</article>
</v-ons-page>
</template>

<script>
// Compornents
import CommonHeader from '../components/Molecules/CommonHeader'

export default {
	name: 'SendingPersonalData',
	components: {
		CommonHeader
	},
	data () {
		return {
		}
	},
	computed: {
	},
	mounted () {
		// スゴ得の場合、コンテンツの高さをemitする。
		const domContainer = document.getElementById('js_sending-personal-data_container')
		if (domContainer) {
			this.$emit('containerHeight', domContainer.clientHeight)
		}
	},
	beforeDestroy () {
		// スゴ得の場合、コンテンツの高さをautoでemitする。
		this.$emit('containerHeight', 'auto')
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";
.sending-personal-data {
	.is-sp {
		display: block;
	}
	@media screen and(min-width: 480px) {
		.is-sp {
			display: none;
		}
	}
	.common-header-title__heading {
		line-height: 1.15;
	}
	/deep/ .page__background {
		background-color: $background-primary;
	}
	&__container {
		padding: $spacing-10 $spacing-14 0;
	}
	&__title {
		font-size: $font-size-16;
		margin: 0 0 $spacing-18;
	}
	&__table {
		font-size: $font-size-12;
		width: 100%;
		border-top: 1px solid $line-primary;
		border-left: 1px solid $line-primary;
		border-spacing: 0;
	}
	&__table--title {
		background-color: $background-secondary;
		border-right: 1px solid $line-primary;
		border-bottom: 1px solid $line-primary;
		padding: $spacing-8;
	}
	&__table--description {
		border-right: 1px solid $line-primary;
		border-bottom: 1px solid $line-primary;
		padding: $spacing-8;
	}
}
</style>
